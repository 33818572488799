import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import moment from 'moment';
import toastr from 'toastr';
import Icon from 'react-fontawesome';
import { Row, Col, FormGroup, Button } from 'react-bootstrap';

import { setVehicleFilter } from '../../../../stores/redux/filter';
import { setBreadcrumbs, setZoom, setPosition } from '../../../../stores/redux/map';

import TrackingApi from '../../../../app/api/TrackingApi';
import { JourneyReportSettings } from '../../../../app/schemas/ReportsSettings';

import { BatteryChargesList, ExceptionsList, SpeedTicketsList, VehicleDetails } from '../../../common/tracking';
import GoogleMap from '../../../common/GoogleMapOld';
import { MainContent } from '../../../common/layout';

import BatteryComponent from '../../../common/chartsComponents/BatteryComponent';
import DoorComponent from '../../../common/chartsComponents/DoorComponent';
import EngineComponent from '../../../common/chartsComponents/EngineComponent';
import FreezerComponent from '../../../common/chartsComponents/FreezerComponent';
import SpeedComponent from '../../../common/chartsComponents/SpeedComponent';
import ZoneTempComponent from '../../../common/chartsComponents/ZoneTempComponent';

import { Callout, ErrorBox, FiltersBox, Loader } from '../../../common/uiElements';
import { DatePeriodInput, VehicleSearch } from '../../../common/inputs';

class ReportJourney extends Component {
	static propTypes = {
		breadcrumbs: PropTypes.arrayOf(
			PropTypes.shape({
				trkPositionLat: PropTypes.number,
				trkPositionLong: PropTypes.number
			})
		),
		filters: PropTypes.object,
		setBreadcrumbs: PropTypes.func,
		setVehicleFilter: PropTypes.func,
		setZoom: PropTypes.func,
		setPosition: PropTypes.func
	}

	constructor(props) {
		super(props);
		this.state = {
			data: null,
			error: null,
			isLoading: false,
			exceptionsData: null,
			parkedPointsData: null,
			vehFleetNumber: null,
			speedTicketsData: null,
			showResult: false
		};
		
		this.periodRanges = {
			'Last 1 hour': [
				moment().add(-1, 'hours'),
				moment()
			],
			'Last 6 hours': [
				moment().add(-6, 'hours'),
				moment()
			],
			'Last 8 hours': [
				moment().add(-8, 'hours'),
				moment()
			],
			'Last 1 day': [
				moment().add(-1, 'days'),
				moment()
			],
			'Last 2 days': [
				moment().add(-2, 'days'),
				moment()
			]
		};
	}

	componentDidMount() {		
		const { vehFleetNumber } = this.props.params;
		if (vehFleetNumber) {
			this.setState({ 
				vehFleetNumber,
				isLoading: true 
			}, this.onSearch);
		}
	}

	componentWillUnmount() {
		this.unmounted = true;
	}
	
	onClear = () => {
		this.props.setBreadcrumbs(null);
		return this.setState({ showResult: false, error: null });
	}

	onSelectVehicle = vehicle => {
		this.onClear();
		this.setState({ vehFleetNumber: vehicle.vehFleetNumber });
	}

	onSearch = () => {
		this.onClear();

		const { vehFleetNumber } = this.state;
		const { filters } = this.props;
		const { startTime, endTime } = filters;


		if (!vehFleetNumber) 
			return this.props.setBreadcrumbs(null);

		this.setState({ isLoading: true });		
		TrackingApi.fullJourneyReport(vehFleetNumber, startTime, endTime)
			.then((data) => {
				if (this.unmounted) return;

				this.setState({ 
					isLoading: false,
					exceptionsData: data.Exceptions,
					parkedPointsData: data.ParkedPoints,
					speedTicketsData: data.SpeedTickets,
					showResult: true 
				});

				this.props.setVehicleFilter(data.VehicleDetails);
				this.props.setBreadcrumbs(data.Breadcrumbs);
				toastr.success('Your search has returned ' + data.Breadcrumbs.length + ' breadcrumbs.', 'Success!');
				window.$('html, body').animate({scrollTop: window.$(this.result).offset().top - 55}, 500);
			})
			.catch(error => {
				console.error(error);
				this.setState({ isLoading: false, error });
			});
	}

	onSelectMapDataPoint = (lat, lng) => {
		window.$('.nav-tabs a[href="#journeyTab"]').click();
		this.setState({ isLoading: true });
		this.props.setZoom(22);
		this.props.setPosition(lat, lng);
		return this.setState({ isLoading: false });
	}
	
	onSelectGraphPoint = index => {
		window.$('.nav-tabs a[href="#journeyTab"]').click();
		this.setState({ isLoading: true });
		var { trkPositionLat, trkPositionLong } = this.props.breadcrumbs[index];
		this.props.setZoom(22);
		this.props.setPosition(trkPositionLat, trkPositionLong);	
		return this.setState({ isLoading: false });
	}

	render() {
		var { isLoading, error, exceptionsData, speedTicketsData, showResult, vehFleetNumber } = this.state;
		var { breadcrumbs } = this.props;
		var { vehicle } = this.props.filters;

		return (
			<div>
				{ isLoading && <Loader overlay /> }
				<MainContent title="Journey Report" subtitle="">
					<FiltersBox>
						<Row>
							<Col md={5}>
								<FormGroup>
									<VehicleSearch onSelectVehicle={this.onSelectVehicle} selectedValue={vehFleetNumber}/>
								</FormGroup>
							</Col>
							<Col md={5}>
								<FormGroup>
									<DatePeriodInput 
										ranges={this.periodRanges}
										showHours={true} 
										startDateInMinutes={JourneyReportSettings.dateStartMinutes} 
										endDateInMinutes={JourneyReportSettings.dateFinishMinutes}
										periodLimitInMinutes={JourneyReportSettings.periodLimitInMinutes}
										onChange={this.onClear}
									/>
								</FormGroup>
							</Col>
							<Col md={2}>
								<FormGroup>
									<Button
										block
										bsStyle="primary"
										disabled={vehicle ? false : true}
										onClick={this.onSearch}
									>
										Search <Icon name="search" />
									</Button>
								</FormGroup>
							</Col>
						</Row>
					</FiltersBox>
					{
						error ? (
							<ErrorBox error={error} retryFunc={this.onSearch} />
						) : (
							vehicle && showResult && (
								<div ref={ref => this.result = ref}>
									{
										breadcrumbs && breadcrumbs.length === 0 ? (
											<Callout color="warning" title="No Data" text="Change the filters and try again." />
										) : (
											<Fragment>
												<Row>
													<Col xs={12} className="text-right">
														<Link to="/tracking/map/fullMap"><u>Click here to see the live information within the full map</u></Link>
													</Col>
												</Row>
												<div className="nav-tabs-custom">
													<ul className="nav nav-tabs">
														<li className="active">
															<a href="#journeyTab" data-toggle="tab" aria-expanded="true">
																<Icon name="map" /> <span className="hidden-sm hidden-xs">Journey</span>
															</a>
														</li>
														<li>
															<a href="#sensorsTab" data-toggle="tab" aria-expanded="false">
																<Icon name="microchip" /> <span className="hidden-sm hidden-xs">Sensors</span>
															</a>
														</li>
														{
															// If has speed check sensor
															vehicle.vehSetHasSpeedCheck && (
																<li>
																	<a href="#speedTicketsTab" data-toggle="tab" aria-expanded="false">
																		<Icon name="dashboard" /> <span className="hidden-sm hidden-xs">Speed Tickets</span> ({speedTicketsData.length})
																	</a>
																</li>
															)
														}
														{
															// If is a Pallet Jack
															vehicle.vehTypeID === 1 && (
																<li>
																	<a href="#batteryChargesTab" data-toggle="tab" aria-expanded="false">
																		<Icon name="battery-3" /> <span className="hidden-sm hidden-xs">Battery Charges</span>
																	</a>
																</li>
															)
														}
														<li>
															<a href="#exceptionsTab" data-toggle="tab" aria-expanded="false">
																<Icon name="warning" /> <span className="hidden-sm hidden-xs">Exceptions</span> ({exceptionsData.length})
															</a>
														</li>
														<li>
															<a href="#vehicleDetailsTab" data-toggle="tab" aria-expanded="false">
																<Icon name="tasks" /> <span className="hidden-sm hidden-xs">Vehicle Details</span>
															</a>
														</li>
													</ul>
													<div className="tab-content">
														<div className="tab-pane active" id="journeyTab">
															<GoogleMap height="58vh" typeControl={true} />
														</div>
														<div className="tab-pane" id="sensorsTab">
															<Row>
																<ZoneTempComponent responsiveClass="col-lg-6" onSelectGraphPoint={this.onSelectGraphPoint}/>
																<BatteryComponent responsiveClass="col-lg-6" onSelectGraphPoint={this.onSelectGraphPoint}/>
																<DoorComponent responsiveClass="col-lg-6" onSelectGraphPoint={this.onSelectGraphPoint}/>
																<EngineComponent responsiveClass="col-lg-6" onSelectGraphPoint={this.onSelectGraphPoint}/>
																<FreezerComponent responsiveClass="col-lg-6" onSelectGraphPoint={this.onSelectGraphPoint}/>
																<SpeedComponent responsiveClass="col-lg-6" onSelectGraphPoint={this.onSelectGraphPoint}/>
															</Row>
														</div>
														{
															// If has speed check sensor
															vehicle.vehSetHasSpeedCheck && (
																<div className="tab-pane" id="speedTicketsTab">
																	<SpeedTicketsList onSelectSpeedTicket={this.onSelectMapDataPoint} speedTicketsData={speedTicketsData}/>
																</div>
															)
														}
														{
															// If is a Pallet Jack
															vehicle.vehTypeID === 1 && (
																<div className="tab-pane" id="batteryChargesTab">
																	<BatteryChargesList showFleet={false} vehFleetNumber={vehicle.vehFleetNumber} fetchWithPeriod={false} />
																</div>
															)
														}
														<div className="tab-pane" id="exceptionsTab">
															<ExceptionsList exceptionsData={exceptionsData} onSelectException={this.onSelectMapDataPoint}/>
														</div>
														<div className="tab-pane" id="vehicleDetailsTab">
															<VehicleDetails />
														</div>
													</div>
												</div>
											</Fragment>
										)
									}
								</div>
							)
						)
					}
				</MainContent>
			</div>
		);
	}
}

const mapDispatchToProps = { setVehicleFilter, setBreadcrumbs, setZoom, setPosition };
const mapStateToProps = state => ({
	filters: state.filters,
	breadcrumbs: state.map.breadcrumbs
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportJourney);